<template>
    <div class="modal" :class="{'is-active': $parent.show_create_user_modal}">
        <div class="modal-background" @click.self="$parent.show_create_user_modal = false"></div>
        <div class="modal-card">
            <div class="has-background-white-ter p-2">
                <span>Create new user</span>
            </div>
            <div class="modal-card-body">
                
            </div>
            <!-- <div class="modal-card-foot is-block p-2">
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            users_list: [
                {id:1, name:'User 1'},
                {id:2, name:'User 2'},
            ]
        }
    },

}
</script>

<style>

</style>